export default {
  name: '313C',
  recruitment_game: 'Jogo de Recrutamento',
  back: 'Voltar',
  cancel: 'Cancelar',
  continue: 'Continuar',
  skip: 'Ignorar',
  start: 'Iniciar',
  close: 'Fechar',
  help: 'Ajuda',
  time_left: 'Tempo Restante',
  progress: 'Progresso',
  privacy_policy: 'Política de Privacidade',
  cookies_policy: 'Política de Cookies e Termos',
  faq: 'Perguntas e Respostas',
  messages: 'Mensagens',
  unread: 'não lida',
  start_recording: 'Iniciar a gravação',
  stop_recording: 'Parar a gravação',
  remove_record: 'Remover registo',
  play_video: 'Reproduzir',
  stop_video: 'Parar',
  mute_video: 'Silenciar',
  unmute_video: 'Ativar som',
  replay_video: 'Reproduzir novamente',
  choose: 'Escolher',
  badges: {
    title: 'Distintivos',
    badge: 'Distintivo',
    my_badges: 'Minhas Distintivos',
    received: 'Você recebeu um distintivo',
    continue: 'Continue jogando',
    show_badges: 'Ver distintivos',
    show_badge: 'Ver a recompensa',
  },
  record_test: {
    intro: 'TESTE DE CONFIGURAÇÕES DE CÂMERA E MICROFONE',
    content:
      'Antes de começar a usar a ferramenta, teste suas configurações. No campo abaixo, você deve ver a imagem da sua câmera. Se você a vê, significa que suas configurações provavelmente estão corretas - para ter certeza, grave e reproduza um vídeo. Se você não vê a imagem da câmera, não consegue reproduzir a gravação ou tem outras dúvidas, clique no botão "RESOLVER PROBLEMAS".',
    troubleshoot: 'Resolver Problemas',
    settings: 'Configurações',
  },
  questions: {
    progress: 'Pergunta',
    accept: 'Confirmar',
    question_has_already_been_answered: 'Esta pergunta já foi respondida.',
  },
  not_found: {
    code: '404',
    title: 'Página não existe',
    content:
      'A página que você procurava não foi encontrada. Pode ter sido excluída, renomeada ou nunca existiu',
  },
  phone: 'Número de telefone',
  select_language: 'Escolha o idioma / Choose language',
  title:
    'Esta página utiliza cookies para proporcionar uma ótima experiência ao usuário. Ao utilizar o 313C, você concorda com o uso',
  link: 'de cookies por parte de nós',
  button: 'Fechar',
  warning: {
    title: 'Ocorreu um erro',
    content: 'Tente novamente, e se o erro persistir, avise-nos em',
    content_without_email:
      'Tente novamente, e se o erro persistir, entre em contato com o suporte técnico.',
    confirm: 'Fechar',
  },
  end_time: {
    title: 'Relatar um erro',
    content: 'Se ocorrer um erro durante o seu jogo, entre em contato pelo email:',
  },
  record_video_error: {
    not_allowed_title: 'Sem permissões',
    not_allowed_desc:
      'Sem acesso à câmera e/ou microfone. Verifique as configurações do navegador e conceda as permissões.',
    not_found_title: 'Nenhum dispositivo conectado',
    not_found_desc:
      'Nenhuma câmera ou microfone detectado. Verifique se seus dispositivos estão corretamente conectados.',
    not_readable_title: 'Dispositivo sendo usado por outro aplicativo',
    not_readable_desc:
      'A câmera e/ou o microfone está sendo usado por outro aplicativo. Feche-o e tente novamente (pode ser necessário atualizar a página).',
    unknown_title: 'Erro desconhecido',
    unknown_desc:
      'Ocorreu um erro desconhecido (por exemplo, problemas de driver ou dispositivo danificado). Consulte a seção de Solução de Problemas e tente novamente.',
  },
  unsupported_os: {
    content: `
      <span class="swal2-title">Dispositivo não suportado</span>
      <hr>
      <div class="swal2-wrapper">
        <p>Para continuar, por favor, utilize <strong>outro dispositivo e/ou navegador.</strong></p>
        <p>A ferramenta de recrutamento que você pretende utilizar inclui pelo menos uma tarefa que requer que você grave sua resposta em formato de vídeo. Infelizmente, nem todos os dispositivos e/ou navegadores permitem fazer isso corretamente.</p>
        <p><strong>Se você está vendo esta tela, significa que o seu dispositivo e/ou navegador não garantem a gravação e o envio corretos de um arquivo de vídeo.</strong> Abaixo, você encontrará as configurações recomendadas.</p>
        <br>
        <p><strong>Dispositivos de desktop:</strong></p>
        <p>Windows: Google Chrome, Firefox, Opera, MS Edge</p>
        <p>macOS: Google Chrome, Firefox, Opera, Safari, MS Edge</p>
        <p>Ubuntu: Google Chrome, Firefox, Opera</p>
        <br>
        <p><strong>Dispositivos móveis:</strong></p>
        <p>Andriod: Google Chrome, Firefox, Opera</p>
        <p>iOS (iPhone/iPad): Safari</p>
      </div>
      <hr>
    `,
  },
  skip_stage_without_record: {
    title: 'Tem a certeza de que deseja ignorar a tarefa?',
    content:
      '<p>A gravação de uma resposta em vídeo para esta tarefa não é obrigatória, mas é recomendada - ajudar-nos-á a conhecer-te melhor. Tem a certeza de que desejas saltar a gravação de uma resposta em vídeo?</p>',
  },
  skip_stage_with_record: {
    title: 'Tem a certeza de que deseja ignorar a tarefa?',
    content:
      '<p>Pretende pular o salvamento da resposta em vídeo gravada - não estará acessível para a pessoa que conduz o processo de recrutamento. O envio de uma resposta em vídeo para esta tarefa não é obrigatório, mas é recomendado - ajudar-nos-á a conhecer-te melhor. Tem a certeza de que desejas saltar o salvamento da resposta em vídeo gravada?</p>',
  },
  instruction_camera: {
    content: `
      <span class="swal2-title">Resolução de Problemas com Câmera e Microfone</span>
      <hr>
      <div class="swal2-wrapper">
      <p>Se a gravação ou reprodução não estiver correta, você pode seguir alguns passos que devem resolver o problema. As causas mais comuns de problemas vêm das configurações do dispositivo, incluindo outros programas ou aplicações que estão utilizando a câmera e/ou o microfone simultaneamente.</p>
      <p>Soluções possíveis:</p>
      <ol>
        <li>Certifique-se de que o navegador tem permissões para usar o microfone e a câmera. Se não, altere as configurações e atualize a página.</li>
        <li>Garanta que nenhum outro aplicativo ou programa esteja usando a câmera/microfone no momento (por exemplo, MS Teams, Photo Booth ou FaceTime) - feche os aplicativos que estão bloqueando a possibilidade de usar a câmera pelo navegador e atualize a página.</li>
        <li>Verifique se o seu navegador está atualizado para a versão mais recente - se não, faça a atualização e tente o teste novamente.</li>
        <li>Se as etapas acima não ajudaram, tente usar outro navegador ou dispositivo.</li>
      </ol>
      <p>Se o problema persistir, nos informe em { supportEmail } enviando informações sobre o dispositivo e navegador nos quais o problema ocorre.</p>
      </div>
      <hr>
    `,
  },
  recaptcha: 'Marque a Recaptcha',
  phone_incorrect: 'Digite um número de telefone válido',
  missing_answer_error:
    'Para continuar, selecione duas respostas: uma com o polegar para cima e outra com o polegar para baixo.',
  rotate_phone_video: 'Gire o telefone horizontalmente para ver o vídeo em um tamanho maior',
  rotate_phone_image: 'Gire o telefone horizontalmente para ver a imagem em um tamanho maior',
  attachments: 'Anexos',
};
