export default {
  name: '313C',
  recruitment_game: 'Igra za zaposlitev',
  back: 'Nazaj',
  cancel: 'Prekliči',
  continue: 'Nadaljuj',
  skip: 'Preskoči',
  start: 'Začni',
  close: 'Zapri',
  help: 'Pomoč',
  time_left: 'Preostali čas',
  progress: 'Napredek',
  privacy_policy: 'Pravilnik o zasebnosti',
  cookies_policy: 'Pravilnik o piškotkih in pogoji',
  faq: 'Vprašanja in odgovori',
  messages: 'Sporočila',
  unread: 'neprebrano',
  start_recording: 'Začetek snemanja',
  stop_recording: 'Ustavitev snemanja',
  remove_record: 'Odstranitev zapisa',
  play_video: 'Predvajaj',
  stop_video: 'Ustavi',
  mute_video: 'Utišaj',
  unmute_video: 'Vklopi zvok',
  replay_video: 'Ponovno predvajaj',
  choose: 'Izberite',
  badges: {
    title: 'Značke',
    badge: 'Značka',
    my_badges: 'Moje značke',
    received: 'Prejmete značko',
    continue: 'Nadaljuj z igro',
    show_badges: 'Oglejte si značk',
    show_badge: 'Glej nagrado',
  },
  record_test: {
    intro: 'TEST NASTAVITEV KAMERE IN MIKROFONA',
    content:
      'Preden začnete uporabljati orodje, preizkusite svoje nastavitve. V spodnjem polju bi morali videti sliko iz svoje kamere. Če jo vidite, to pomeni, da so vaše nastavitve verjetno pravilne - za prepričanje posnemite in predvajajte video. Če slike iz kamere ne vidite, ne morete predvajati posnetka ali imate druge dvome, kliknite na gumb "ODPRAVA TEŽAV".',
    troubleshoot: 'Odprava Težav',
    settings: 'Nastavitve',
  },
  questions: {
    progress: 'Vprašanje',
    accept: 'Potrdi',
    question_has_already_been_answered: 'To vprašanje je že bilo odgovorjeno.',
  },
  not_found: {
    code: '404',
    title: 'Stran ne obstaja',
    content:
      'Stran, ki ste jo iskali, ni bila najdena. Lahko je bila izbrisana, spremenjeno ime ali sploh ni obstajala',
  },
  phone: 'Telefonska številka',
  select_language: 'Izberite jezik / Choose language',
  title:
    'Ta stran uporablja piškotke, da vam omogoči odlično uporabniško izkušnjo. Z uporabo 313C sprejemate uporabo',
  link: 'piškotkov s strani nas',
  button: 'Zapri',
  warning: {
    title: 'Prišlo je do napake',
    content: 'Poskusite znova, če se napaka ponavlja, nas obvestite na',
    content_without_email:
      'Poskusite znova, če se napaka ponavlja, se obrnite na tehnično podporo.',
    confirm: 'Zapri',
  },
  end_time: {
    title: 'Prijavite napako',
    content: 'Če med vašo igro pride do napake, nas kontaktirajte na e-poštnem naslovu:',
  },
  record_video_error: {
    not_allowed_title: 'Manjkajoča dovoljenja',
    not_allowed_desc:
      'Ni dostopa do kamere in/ali mikrofona. Preverite nastavitve brskalnika in podelite potrebna dovoljenja.',
    not_found_title: 'Ni priključenih naprav',
    not_found_desc:
      'Kamera in/ali mikrofon nista bila zaznana. Preverite, ali so naprave pravilno priključene.',
    not_readable_title: 'Napravo uporablja druga aplikacija',
    not_readable_desc:
      'Kamero in/ali mikrofon uporablja druga aplikacija. Zaprite jo in poskusite znova (morda bo potrebno osvežiti stran).',
    unknown_title: 'Neznana napaka',
    unknown_desc:
      'Prišlo je do neznane napake (npr. težave z gonilniki ali poškodovana naprava). Preverite razdelek Odpravljanje težav in poskusite znova.',
  },
  unsupported_os: {
    content: `
      <span class="swal2-title">Ne podprta naprava</span>
      <hr>
      <div class="swal2-wrapper">
        <p>Za nadaljevanje uporabite <strong>drugo napravo in/ali brskalnik.</strong></p>
        <p>Orodje za zaposlovanje, ki ga nameravate uporabiti, vključuje vsaj eno nalogo, pri kateri morate odgovor posneti v video formatu. Žal vsi naprave in/ali brskalniki tega ne omogočajo pravilno.</p>
        <p><strong>Če vidite to zaslon, to pomeni, da vaša naprava in/ali brskalnik ne zagotavljata pravilnega snemanja in pošiljanja video datoteke.</strong> Spodaj boste našli priporočene nastavitve.</p>
        <br>
        <p><strong>Namizne naprave:</strong></p>
        <p>Windows: Google Chrome, Firefox, Opera, MS Edge</p>
        <p>macOS: Google Chrome, Firefox, Opera, Safari, MS Edge</p>
        <p>Ubuntu: Google Chrome, Firefox, Opera</p>
        <br>
        <p><strong>Prenosne naprave:</strong></p>
        <p>Andriod: Google Chrome, Firefox, Opera</p>
        <p>iOS (iPhone/iPad): Safari</p>
      </div>
      <hr>
    `,
  },
  skip_stage_without_record: {
    title: 'Ste prepričani, da želite preskočiti nalogo?',
    content:
      '<p>Snemanje videoposnetka odgovora pri tej nalogi ni obvezno, vendar priporočeno - pomagalo nam bo, da vas bolje spoznamo. Ste prepričani, da želite preskočiti snemanje videoposnetka odgovora?</p>',
  },
  skip_stage_with_record: {
    title: 'Ste prepričani, da želite preskočiti nalogo?',
    content:
      '<p>Nameravate preskočiti shranjevanje posnetega videoodgovora - ne bo na voljo osebi, ki izvaja postopek izbora. Nalaganje videoodgovora za to nalogo ni obvezno, vendar je priporočljivo - pomagalo nam bo, da vas bolje spoznamo. Ste prepričani, da želite preskočiti shranjevanje posnetega videoodgovora?</p>',
  },
  instruction_camera: {
    content: `
      <span class="swal2-title">Odprava težav s kamero in mikrofonom</span>
      <hr>
      <div class="swal2-wrapper">
      <p>Če snemanje ali predvajanje ni pravilno, lahko sledite nekaj korakom, ki bi morali rešiti problem. Najpogostejši vzroki težav izhajajo iz nastavitev naprave, vključno z drugimi programi ali aplikacijami, ki hkrati uporabljajo kamero in/ali mikrofon.</p>
      <p>Možne rešitve:</p>
      <ol>
        <li>Prepričajte se, da ima brskalnik dovoljenja za uporabo mikrofona in kamere. Če ne, spremenite nastavitve in osvežite stran.</li>
        <li>Zagotovite, da nobena druga aplikacija ali program trenutno ne uporablja kamere/mikrofona (npr. MS Teams, Photo Booth ali FaceTime) - zaprite aplikacije, ki blokirajo možnost uporabe kamere preko brskalnika in osvežite stran.</li>
        <li>Preverite, ali je vaš brskalnik posodobljen na najnovejšo različico - če ne, izvedite posodobitev in ponovno izvedite test.</li>
        <li>Če zgornji koraki niso pomagali, poskusite uporabiti drug brskalnik ali napravo.</li>
      </ol>
      <p>Če težava še vedno obstaja, nam sporočite na { supportEmail } z informacijami o napravi in brskalniku, na katerih se pojavi težava.</p>
      </div>
      <hr>
    `,
  },
  recaptcha: 'Označi Recaptcha',
  phone_incorrect: 'Vnesite pravilno telefonsko številko',
  missing_answer_error:
    'Za nadaljevanje izberite dva odgovora: enega s palcem gor in enega s palcem dol.',
  rotate_phone_video: 'Zavrtite telefon vodoravno, da si ogledate video v večji velikosti',
  rotate_phone_image: 'Zavrtite telefon vodoravno, da si ogledate sliko v večji velikosti',
  attachments: 'Priloge',
};
