import button from './button';
import chip from './chip';
import datepicker from './datepicker';
import inputtext from './inputtext';
import inputnumber from './inputnumber';
import global from './global';
import ripple from './ripple';
import tooltip from './tooltip';
import textarea from './textarea';
import checkbox from './checkbox';
import multiselect from './multiselect';
import message from './message';
import select from './select';
import menu from './menu';

export default {
  global,
  directives: {
    ripple,
    tooltip,
  },
  button,
  chip,
  datepicker,
  inputtext,
  inputnumber,
  textarea,
  checkbox,
  multiselect,
  message,
  select,
  menu,
};
