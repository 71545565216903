export default {
  name: '313C',
  recruitment_game: 'Rekrutációs Játék',
  back: 'Vissza',
  cancel: 'Mégse',
  continue: 'Folytatás',
  skip: 'Átugrás',
  start: 'Indítás',
  close: 'Bezárás',
  help: 'Segítség',
  time_left: 'Hátralévő Idő',
  progress: 'Előrehaladás',
  privacy_policy: 'Adatvédelmi irányelvek',
  cookies_policy: 'Cookie-kra vonatkozó szabályzat és Feltételek',
  faq: 'Kérdések és Válaszok',
  messages: 'Üzenetek',
  unread: 'nem olvasott',
  start_recording: 'Felvétel indítása',
  stop_recording: 'Felvétel leállítása',
  remove_record: 'Felvétel eltávolítása',
  play_video: 'Lejátszás',
  stop_video: 'Megállítás',
  mute_video: 'Némítás',
  unmute_video: 'Némítás feloldása',
  replay_video: 'Újrajátszás',
  choose: 'Válassz',
  badges: {
    title: 'Jelvények',
    badge: 'Jelvény',
    my_badges: 'Az én Jelvényeim',
    received: 'Kapsz egy jelvényt',
    continue: 'Játssz tovább',
    show_badges: 'Jelvények megtekintése',
    show_badge: 'Nézd meg a jutalmadat',
  },
  record_test: {
    intro: 'KAMERA ÉS MIKROFON BEÁLLÍTÁSOK TESZTELÉSE',
    content:
      'Mielőtt elkezdené használni az eszközt, tesztelje a beállításait. Az alábbi mezőben látnia kellene a kamerájának képét. Ha látja, az jelenti, hogy a beállításai valószínűleg helyesek - a biztonság kedvéért vegyen fel és játsszon le egy videót. Ha nem látja a kamerájának képét, nem tudja lejátszani a felvételt, vagy más kétségei vannak, kattintson a „HIBAELHÁRÍTÁS” gombra.',
    troubleshoot: 'Hibaelhárítás',
    settings: 'Beállítások',
  },
  questions: {
    progress: 'Kérdés',
    accept: 'Megerősít',
    question_has_already_been_answered: 'Ez a kérdés már meg lett válaszolva.',
  },
  not_found: {
    code: '404',
    title: 'Az oldal nem létezik',
    content:
      'A keresett oldal nem található. Lehet, hogy eltávolították, átnevezték vagy egyszerűen soha nem is létezett.',
  },
  phone: 'Telefonszám',
  select_language: 'Válasszon nyelvet / Choose language',
  title:
    'Az oldal cookie-kat használ, hogy a felhasználói élmény színvonalas legyen. Az oldalon történő látogatásodkor elfogadod a számunkra történő',
  link: 'cookie-k használatát',
  button: 'Bezárás',
  warning: {
    title: 'Do hibát észleltünk',
    content: 'Próbálja meg újra, és ha a hiba ismételten előfordul, küldjön nekünk értesítést',
    content_without_email:
      'Próbálja meg újra, és ha a hiba ismételten előfordul, adja meg nekünk a műszaki támogatásunkon keresztül',
    confirm: 'Bezárás',
  },
  end_time: {
    title: 'Hibabejelentés',
    content:
      'Ha a játék során hibát tapasztal, kérjük, lépjen kapcsolatba velünk e-mailen az alábbi címen:',
  },
  record_video_error: {
    not_allowed_title: 'Nincs engedély',
    not_allowed_desc:
      'Nincs hozzáférés a kamerához és/vagy mikrofonhoz. Ellenőrizze a böngésző beállításait, és adja meg a szükséges engedélyeket.',
    not_found_title: 'Nincs csatlakoztatott eszköz',
    not_found_desc:
      'Nem érzékeltünk kamerát vagy mikrofont. Ellenőrizze, hogy az eszközök megfelelően vannak-e csatlakoztatva.',
    not_readable_title: 'Az eszközt egy másik alkalmazás használja',
    not_readable_desc:
      'A kamerát és/vagy mikrofont egy másik alkalmazás használja. Zárja be azt az alkalmazást, és próbálja újra (szükség lehet az oldal frissítésére).',
    unknown_title: 'Ismeretlen hiba',
    unknown_desc:
      'Ismeretlen hiba történt (pl. illesztőprogram-probléma vagy sérült eszköz). Olvassa el a Hibaelhárítás szakaszt, és próbálja újra.',
  },
  unsupported_os: {
    content: `
      <span class="swal2-title">Nem támogatott eszköz</span>
      <hr>
      <div class="swal2-wrapper">
        <p>A folytatáshoz használjon <strong>másik eszközt és/vagy böngészőt.</strong></p>
        <p>A kiválasztott toborzási eszköz legalább egy olyan feladatot tartalmaz, amelyben válaszát videó formátumban kell rögzítenie. Sajnos nem minden eszköz és/vagy böngésző teszi lehetővé ezt a helyes módon.</p>
        <p><strong>Ha látja ezt a képernyőt, az azt jelenti, hogy az Ön eszköze és/vagy böngészője nem garantálja a helyes videófájl rögzítését és elküldését.</strong> Az alábbiakban találja az ajánlott beállításokat.</p>
        <br>
        <p><strong>Asztali eszközök:/strong></p>
        <p>Windows: Google Chrome, Firefox, Opera, MS Edge</p>
        <p>macOS: Google Chrome, Firefox, Opera, Safari, MS Edge</p>
        <p>Ubuntu: Google Chrome, Firefox, Opera</p>
        <br>
        <p><strong>Mobil eszközök:</strong></p>
        <p>Andriod: Google Chrome, Firefox, Opera</p>
        <p>iOS (iPhone/iPad): Safari</p>
      </div>
      <hr>
    `,
  },
  skip_stage_without_record: {
    title: 'Biztosan ki szeretnéd hagyni a feladatot?',
    content:
      '<p>A videó válasz rögzítése ebben a feladatban nem kötelező, de ajánlott - segít nekünk jobban megismerni Önt. Biztosan át szeretné ugrani a videó válasz rögzítését?</p>',
  },
  skip_stage_with_record: {
    title: 'Biztosan ki szeretnéd hagyni a feladatot?',
    content:
      '<p>Az ön szándékában áll kihagyni a rögzített videó válasz mentését - nem lesz elérhető a toborzást végző személy számára. A videó válasz feltöltése ennek a feladatnak nem kötelező, de ajánlott - segít nekünk jobban megismerni Önt. Biztosan szeretné kihagyni a rögzített videó válasz mentését?</p>',
  },
  instruction_camera: {
    content: `
      <span class="swal2-title">Kamera és Mikrofon Hiba Elhárítása</span>
      <hr>
      <div class="swal2-wrapper">
      <p>Ha a felvétel vagy lejátszás helytelen, követhet néhány lépést, amelyek megoldhatják a problémát. A problémák leggyakoribb okai a készülék beállításaiból adódnak, beleértve az egyidejűleg futó egyéb programokat vagy alkalmazásokat, amelyek használják a kamerát és/vagy a mikrofont.</p>
      <p>Lehetséges megoldások:</p>
      <ol>
        <li>Győződjön meg arról, hogy a böngészőnek van engedélye a mikrofon és a kamera használatára. Ha nincs, módosítsa a beállításokat és frissítse az oldalt.</li>
        <li>Győződjön meg arról, hogy nincs más alkalmazás vagy program, amely éppen használja a kamerát/mikrofont (pl. MS Teams, Photo Booth vagy FaceTime) - zárja be azokat az alkalmazásokat, amelyek blokkolják a kamera böngésző általi használatát, és frissítse az oldalt.</li>
        <li>Ellenőrizze, hogy a böngészője frissítve van-e a legújabb verzióra - ha nem, végezzen frissítést és indítsa újra a tesztet.</li>
        <li>Ha a fenti lépések nem segítettek, próbáljon meg egy másik böngészőt vagy eszközt használni.</li>
      </ol>
      <p>Ha a probléma továbbra is fennáll, tájékoztasson minket a { supportEmail } címen, küldje el az eszköz és a böngésző adatait, ahol a probléma előfordul.</p>
      </div>
      <hr>
    `,
  },
  recaptcha: 'Válassza a recaptcha lehetőséget',
  phone_incorrect: 'Adja meg a helyes telefonszámot',
  missing_answer_error:
    'A továbblépéshez válasszon két választ: egyet felfelé mutató hüvelykujjal és egyet lefelé mutató hüvelykujjal.',
  rotate_phone_video: 'Forgassa el a telefonját vízszintesen, hogy nagyobb méretben lássa a videót',
  rotate_phone_image: 'Forgassa el a telefonját vízszintesen, hogy nagyobb méretben lássa a képet',
  attachments: 'Mellékletek',
};
