import Stage from '@/domain/entities/flow/Stage';
import Badge from '@/domain/entities/flow/Badge';
import Feedback from '@/domain/entities/flow/Feedback';

interface Props {
  id: string;
  type: string;
  order: number;
  completedAt: Date | null;
  title: string;
  body: string;
  feedback: Feedback | null;
  badge: Badge | null;
  sideImageUrl: string;
  mobileSideImageUrl: string;
  imageUrl: string;
}

export default class GameMessage extends Stage {
  readonly feedback: Feedback | null;
  readonly sideImageUrl: string;
  readonly imageUrl: string;
  readonly mobileSideImageUrl: string;
  readonly title: string;
  readonly body: string;

  constructor({
    id,
    type,
    order,
    completedAt,
    title,
    body,
    feedback,
    badge,
    sideImageUrl,
    mobileSideImageUrl,
    imageUrl,
  }: Props) {
    super({ id, type, order, completedAt, badge });

    this.title = title;
    this.body = body;
    this.feedback = feedback;
    this.sideImageUrl = sideImageUrl;
    this.mobileSideImageUrl = mobileSideImageUrl;
    this.imageUrl = imageUrl;
  }
}
