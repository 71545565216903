import { defineStore } from 'pinia';
import * as Sentry from '@sentry/vue';
import api from '@/common/axios/axios';
import flowFactory from '@/domain/factories/flowFactory';
import { useFlowStore } from '@/stores/flowStore';

export interface Session {
  session: {
    candidate: {
      data: Candidate;
    };
    id: string;
    game_remaining_time: number;
    game_started_at: Date | null;
    game_finished_at: Date | null;
    locale: string;
    registered_at: Date;
  } | null;
  gameTimeLimit: string | null;
  currentRoute: CurrentRoute | null;
}

export interface Candidate {
  first_name: string;
  last_name: string;
}

export interface CurrentRoute {
  params: {
    recruitmentProcessId?: string;
    sessionId?: string;
  };
}

export const useSessionStore = defineStore('sessionStore', {
  state: (): Session => ({
    session: null,
    gameTimeLimit: null,
    currentRoute: null,
  }),
  actions: {
    async fetchSessionGameData(): Promise<void> {
      const response = await api.get('session');
      this.session = response.data.data;
    },
    async fetchGameStages(): Promise<void> {
      const flowStore = useFlowStore();
      const response = await api.get('stages');
      const flowData = flowFactory(response.data.data);
      flowStore.setFlow(flowData);
    },
    async fetchSession(sessionId: string | string[]): Promise<void> {
      const response = await api.get(`session/${sessionId}`);
      this.session = response.data.data;
      Sentry.setTag('session_id', this.sessionId);
    },
    async setSessionLocale({
      recruitmentProcessId,
      sessionId = null,
      locale,
    }: {
      recruitmentProcessId: string;
      sessionId?: string | null;
      locale: string;
    }) {
      const endpoint =
        sessionId === null
          ? `locale/${recruitmentProcessId}`
          : `locale/${recruitmentProcessId}/${sessionId}`;
      const response = await api.post(endpoint, { locale });
      return response.data.data;
    },
    async markGameAsStarted(): Promise<void> {
      const response = await api.post('session/start');
      this.session = response.data.data;
    },
    async markGameAsFinished(): Promise<void> {
      await api.post('session/finish');
      if (this.session) {
        this.session.game_finished_at = new Date();
      }
    },
    async markGameAsTimedOut(): Promise<void> {
      await api.post('session/end-of-time');
      if (this.session) {
        this.session.game_finished_at = new Date();
      }
    },
    setGameTimeLimit(data: string | null) {
      this.gameTimeLimit = data;
    },
    setCurrentRoute(data: CurrentRoute | null) {
      this.currentRoute = data;
    },
  },
  getters: {
    hasSessionData: (state): boolean => state.session !== null,
    remainingTime: (state): number => state.session?.game_remaining_time ?? 0,
    gameIsStarted: (state): boolean => !!state.session?.game_started_at,
    gameIsFinished: (state): boolean => !!state.session?.game_finished_at,
    hasGameTimeLimit: (state): boolean => !!state.gameTimeLimit,
    sessionId: (state): string | null => state.session?.id ?? null,
    sessionLocale: (state): string | null => state.session?.locale ?? null,
    getCurrentRoute: (state): CurrentRoute | null =>
      state.currentRoute !== null ? state.currentRoute : null,
  },
});
