import { defineStore } from 'pinia';
import { COOKIES_ACCEPTED } from '@/names/localStorage.names';

export const useCookieStore = defineStore('cookie', {
  state: () => ({
    isCookieAccepted: false,
  }),
  actions: {
    setCookieAccepted(value: boolean): void {
      this.isCookieAccepted = value;
    },
    getCookieStatus(): void {
      const cookieValue = localStorage.getItem(COOKIES_ACCEPTED) === 'true';
      this.isCookieAccepted = cookieValue;
    },
    acceptCookies(): void {
      localStorage.setItem(COOKIES_ACCEPTED, 'true');
      this.isCookieAccepted = true;
    },
  },
});
