import { useLayoutStore } from '@/stores/layoutStore';
import { useSessionStore } from '@/stores/sessionStore';
import { useFlowStore } from '@/stores/flowStore';
import { BADGES } from '@/router/routes.names';
import { HTTP_UNAUTHORIZED } from '@/common/axios/httpStatusCodes';
import type { RouteRecordRaw } from 'vue-router';

const badgesRoutes: RouteRecordRaw[] = [
  {
    path: 'badges',
    component: () => import('@/layouts/GameLayout.vue'),
    beforeEnter: async (to, from, next) => {
      const layoutStore = useLayoutStore();
      const sessionStore = useSessionStore();

      try {
        layoutStore.startLoading();
        await sessionStore.fetchSessionGameData();

        if (sessionStore.gameIsStarted) {
          await sessionStore.fetchGameStages();
        }

        next();
      } catch (e: any) {
        const statusCode = e.response?.status;

        if (statusCode === HTTP_UNAUTHORIZED) {
          next();
          return;
        }
        throw new Error(e.message || 'An error occurred during navigation.');
      } finally {
        layoutStore.stopLoading();
      }
    },
    children: [
      {
        path: '',
        name: BADGES,
        component: () => import('@/views/Badges.vue'),
        beforeEnter: async (to, from, next) => {
          const flowStore = useFlowStore();
          const sessionStore = useSessionStore();

          const allStagesCompleted = flowStore.areAllStagesCompleted;
          const gameIsFinished = sessionStore.gameIsFinished;

          if (allStagesCompleted || gameIsFinished) {
            await flowStore.redirectToCurrentStage();
          }

          next();
        },
      },
    ],
  },
];

export default badgesRoutes;
