export default {
  name: '313C',
  recruitment_game: 'Prova di Recruimento',
  back: 'Indietro',
  cancel: 'Annulla',
  continue: 'Continua',
  skip: 'Salta',
  start: 'Inizio',
  close: 'Chiusura',
  help: 'Aiuto',
  time_left: 'Tempo rimasto',
  progress: 'Progresso',
  privacy_policy: 'Politica sulla riservatezza',
  cookies_policy: 'Cookie policy e Condizioni',
  faq: 'Domande e risposte',
  messages: 'Messaggi',
  unread: 'non letta',
  start_recording: 'Avviare la registrazione',
  stop_recording: 'Interrompere la registrazione',
  remove_record: 'Rimuovere la registrazione',
  play_video: 'Riproduci',
  stop_video: 'Ferma',
  mute_video: 'Disattiva audio',
  unmute_video: 'Riattiva audio',
  replay_video: 'Riproduci di nuovo',
  choose: 'Scegli',
  badges: {
    title: 'Distintivi',
    badge: 'Distintivo',
    my_badges: 'I miei Distintivi',
    received: 'Riceverai un distintivo',
    continue: 'Gioca ancora',
    show_badges: 'Visualizza i distintivi',
    show_badge: 'Vedi la ricompensa',
  },
  record_test: {
    intro: 'TEST DELLE IMPOSTAZIONI DI CAMERA E MICROFONO',
    content:
      "Prima di iniziare con lo strumento, testa le tue impostazioni. Nel campo sottostante, dovresti vedere l'immagine dalla tua camera. Se la vedi, significa che le tue impostazioni sono molto probabilmente corrette - per essere sicuro, registra e riproduci un video. Se non vedi l'immagine dalla camera, non riesci a riprodurre la registrazione o hai altri dubbi, clicca sul bottone “RISOLUZIONE PROBLEMI”.",
    troubleshoot: 'Risoluzione Problemi',
    settings: 'Impostazioni',
  },
  questions: {
    progress: 'Domanda',
    accept: 'Conferma',
    question_has_already_been_answered: 'Questa domanda è già stata risposta.',
  },
  not_found: {
    code: '404',
    title: 'La pagina non esiste',
    content:
      'La pagina che stavi cercando non è stata trovata. Potrebbe essere stata rimossa, rinominata o addirittura non esistere.',
  },
  phone: 'Numero di telefono',
  select_language: 'Seleziona una lingua / Choose language',
  title:
    "Questo sito web utilizza i cookie per offrirti un'esperienza utente eccellente. Utilizzando questo sito accetti l'utilizzo",
  link: 'dei cookie da parte nostra',
  button: 'Chiusura',
  warning: {
    title: 'Errore riscontrato',
    content: "Prova di nuovo e se l'errore persiste, facci sapere per ",
    content_without_email: "Prova ancora e se l'errore persiste, contatta il supporto tecnico",
    confirm: 'Chiusura',
  },
  end_time: {
    title: 'Segnala errore',
    content: "Se durante la tua partita si è verificato un errore, contattaci all'indirizzo email:",
  },
  record_video_error: {
    not_allowed_title: 'Nessuna autorizzazione',
    not_allowed_desc:
      'Nessun accesso a fotocamera e/o microfono. Controlla le impostazioni del browser e concedi le autorizzazioni.',
    not_found_title: 'Nessun dispositivo collegato',
    not_found_desc:
      'Nessuna fotocamera o microfono rilevato. Verifica che i dispositivi siano correttamente collegati.',
    not_readable_title: 'Dispositivo in uso da un’altra applicazione',
    not_readable_desc:
      'La fotocamera e/o il microfono sono in uso da un’altra applicazione. Chiudi l’applicazione e riprova (potrebbe essere necessario aggiornare la pagina).',
    unknown_title: 'Errore sconosciuto',
    unknown_desc:
      'Si è verificato un errore sconosciuto (ad es. problemi di driver o dispositivo danneggiato). Consulta la sezione Risoluzione dei problemi e riprova.',
  },
  unsupported_os: {
    content: `
      <span class="swal2-title">Dispositivo non supportato</span>
      <hr>
      <div class="swal2-wrapper">
        <p>Per continuare, ti preghiamo di utilizzare un <strong>dispositivo e/o un browser diverso.</strong></p>
        <p>Lo strumento di reclutamento che intendi utilizzare include almeno un compito che richiede la registrazione della tua risposta in formato video. Purtroppo, non tutti i dispositivi e/o browser consentono di farlo correttamente.</p>
        <p><strong>Se visualizzi questa schermata, significa che il tuo dispositivo e/o browser non garantisce la corretta registrazione e invio di un file video.</strong> Di seguito troverai le impostazioni consigliate.</p>
        <br>
        <p><strong>Dispositivi desktop:</strong></p>
        <p>Windows: Google Chrome, Firefox, Opera, MS Edge</p>
        <p>macOS: Google Chrome, Firefox, Opera, Safari, MS Edge</p>
        <p>Ubuntu: Google Chrome, Firefox, Opera</p>
        <br>
        <p><strong>Dispositivi mobili:</strong></p>
        <p>Andriod: Google Chrome, Firefox, Opera</p>
        <p>iOS (iPhone/iPad): Safari</p>
      </div>
      <hr>
    `,
  },
  skip_stage_without_record: {
    title: 'Sei sicuro di voler saltare il compito?',
    content:
      '<p>La registrazione di una risposta video per questo compito non è obbligatoria, ma consigliata - ci aiuterà a conoscerti meglio. Sei sicuro di voler saltare la registrazione di una risposta video?</p>',
  },
  skip_stage_with_record: {
    title: 'Sei sicuro di voler saltare il compito?',
    content:
      '<p>Hai intenzione di saltare il salvataggio della risposta video registrata: essa non sarà accessibile alla persona che conduce il processo di selezione. Il caricamento di una risposta video per questo compito non è obbligatorio, ma consigliato - ci aiuterà a conoscerti meglio. Sei sicuro di voler saltare il salvataggio della risposta video registrata?</p>',
  },
  instruction_camera: {
    content: `
      <span class="swal2-title">Risoluzione dei Problemi di Camera e Microfono</span>
      <hr>
      <div class="swal2-wrapper">
      <p>Se la registrazione o la riproduzione non è corretta, puoi seguire alcuni passaggi che dovrebbero risolvere il problema. Le cause più comuni dei problemi derivano dalle impostazioni del dispositivo, inclusi altri programmi o applicazioni che utilizzano contemporaneamente la camera e/o il microfono.</p>
      <p>Soluzioni possibili:</p>
      <ol>
        <li>Assicurati che il browser abbia i permessi per usare il microfono e la camera. Se non è così, cambia le impostazioni e aggiorna la pagina.</li>
        <li>Assicurati che nessun'altra applicazione o programma stia attualmente usando la camera/il microfono (es. MS Teams, Photo Booth o FaceTime) - chiudi le applicazioni che bloccano la possibilità di utilizzare la camera tramite il browser e aggiorna la pagina.</li>
        <li>Controlla se il tuo browser è aggiornato all'ultima versione - se non lo è, effettua un aggiornamento e ripeti il test.</li>
        <li>Se i passaggi sopra non hanno aiutato, prova a utilizzare un altro browser o dispositivo.</li>
      </ol>
      <p>Se il problema persiste, faccelo sapere a { supportEmail } inviando informazioni sul dispositivo e sul browser in cui si verifica il problema.</p>
      </div>
      <hr>
    `,
  },
  recaptcha: 'Seleziona ReCaptcha',
  phone_incorrect: 'Inserisci un numero di telefono valido',
  missing_answer_error:
    'Per procedere, seleziona due risposte: una con il pollice in su e una con il pollice in giù.',
  rotate_phone_video:
    'Ruota il telefono in orizzontale per visualizzare il video in un formato più grande',
  rotate_phone_image:
    "Ruota il telefono in orizzontale per visualizzare l'immagine in un formato più grande",
  attachments: 'Allegati',
};
