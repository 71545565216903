import { useLayoutStore } from '@/stores/layoutStore';
import { useSessionStore } from '@/stores/sessionStore';
import { useFlowStore } from '@/stores/flowStore';
import { RECORD_TEST } from '@/router/routes.names';
import { HTTP_UNAUTHORIZED } from '@/common/axios/httpStatusCodes';
import type { RouteRecordRaw } from 'vue-router';

const recordTestRoute: RouteRecordRaw[] = [
  {
    path: 'record-test',
    name: RECORD_TEST,
    component: () => import('@/views/RecordTest.vue'),
    beforeEnter: async (to, from, next) => {
      const layoutStore = useLayoutStore();
      const sessionStore = useSessionStore();
      const flowStore = useFlowStore();

      try {
        layoutStore.startLoading();
        await sessionStore.fetchSessionGameData();

        if (sessionStore.gameIsStarted) {
          await sessionStore.fetchGameStages();
          await flowStore.redirectToCurrentStage();
        }

        next();
      } catch (e: any) {
        const statusCode = e.response?.status;

        if (statusCode === HTTP_UNAUTHORIZED) {
          next();
          return;
        }
        next(false);
        throw new Error(e.message || 'An error occurred during navigation.');
      } finally {
        layoutStore.stopLoading();
      }
    },
  },
];

export default recordTestRoute;
