export default {
  name: '313c',
  recruitment_game: 'Gra Rekrutacyjna',
  back: 'Wróć',
  cancel: 'Anuluj',
  continue: 'Kontynuuj',
  skip: 'Pomiń',
  start: 'Start',
  close: 'Zamknij',
  help: 'Pomoc',
  time_left: 'Pozostały czas',
  progress: 'Postęp',
  privacy_policy: 'Polityka Prywatności',
  cookies_policy: 'Polityka cookies i Warunki',
  faq: 'Pytania i odpowiedzi',
  messages: 'Wiadomości',
  unread: 'nieprzeczytana',
  start_recording: 'Rozpocznij nagrywanie',
  stop_recording: 'Zatrzymaj nagrywanie',
  remove_record: 'Usuń nagranie',
  play_video: 'Odtwarzaj',
  stop_video: 'Zatrzymaj',
  mute_video: 'Wycisz',
  unmute_video: 'Wyłącz wyciszenie',
  replay_video: 'Odtwórz ponownie',
  choose: 'Wybierz',
  badges: {
    title: 'Odznaki',
    badge: 'Odznak',
    my_badges: 'Moje Odznaki',
    received: 'Otrzymujesz odznakę',
    continue: 'Graj dalej',
    show_badges: 'Zobacz odznaki',
    show_badge: 'Zobacz nagrodę',
  },
  record_test: {
    intro: 'TEST USTAWIEŃ KAMERY I MIKROFONU',
    content:
      'Przed przystąpieniem do narzędzia przetestuj swoje ustawienia. W polu poniżej powinieneś_aś widzieć obraz ze swojej kamery. <strong>Jeśli go widzisz</strong> oznacza to, że Twoje ustawienia są najprawdopodobniej poprawne - <strong>dla pewności nagraj i odtwórz video.</strong> Jeśli nie widzisz obrazu z kamery, nie możesz odtworzyć nagrania lub masz inne wątpliwości kliknij w przycisk “ROZWIĄZYWANIE PROBLEMÓW”.',
    troubleshoot: 'Rozwiązywanie Problemów',
    settings: 'Ustawienia',
  },
  questions: {
    progress: 'Pytanie',
    accept: 'Zatwierdź',
    question_has_already_been_answered: 'Odpowiedź na pytanie została już udzielona.',
  },
  not_found: {
    code: '404',
    title: 'Strona nie istnieje',
    content:
      'Strona, której szukałeś, nie została odnaleziona. Mogła zostać usunięta, zmieniono jej nazwę lub w ogóle nie istniała',
    title_hebe: 'Cześć!',
    content_hebe:
      'Proces rekrutacyjny do tegorocznej edycji Management Trainee został zakończony. Zachęcamy do śledzenia innych ofert na naszej stronie <a href="https://kariera.hebe.pl">kariera.hebe.pl</a>',
  },
  phone: 'Numer telefonu',
  select_language: 'Wybierz język / Choose language',
  title:
    'Ta strona używa plików cookies, aby zapewnić Ci wspaniałe wrażenia użytkownika. Korzystając z 313C, akceptujesz używanie przez nas',
  link: 'plików cookies',
  button: 'Zamknij',
  warning: {
    title: 'Wystąpił błąd',
    content: 'Spróbuj ponownie, a jeśli błąd będzie się powtarzał daj nam znać na',
    content_without_email:
      'Spróbuj ponownie, a jeśli błąd będzie się powtarzał skontaktuj się z obsługą techniczną.',
    confirm: 'Zamknij',
  },
  end_time: {
    title: 'Zgłoś błąd',
    content: 'Jeżeli podczas Twojej gry wystąpił błąd prosimy o kontakt pod adresem email:',
  },
  record_video_error: {
    not_allowed_title: 'Brak uprawnień',
    not_allowed_desc:
      'Brak dostępu do kamery i/lub mikrofonu. Sprawdź ustawienia przeglądarki i nadaj uprawnienia.',
    not_found_title: 'Brak podłączonego sprzętu',
    not_found_desc:
      'Nie wykryto kamery i/lub mikrofonu. Sprawdź, czy urządzenia są prawidłowo podłączone.',
    not_readable_title: 'Urządzenie zajęte przez inną aplikację',
    not_readable_desc:
      'Kamera i/lub mikrofon są używane przez inną aplikację. Zamknij ją i spróbuj ponownie (może być niezbędne odświeżenie strony).',
    unknown_title: 'Nieznany błąd',
    unknown_desc:
      'Wystąpił nieznany błąd (np. problem ze sterownikami lub uszkodzone urządzenie). Zapoznaj się z informacjami w sekcji Rozwiązywanie problemów i spróbuj ponownie.',
  },
  unsupported_os: {
    content: `
      <span class="swal2-title">Nieobsługiwane urządzenie</span>
      <hr>
      <div class="swal2-wrapper">
        <p>Aby kontynuować prosimy o skorzystanie z <strong>innego urządzenia i/lub przeglądarki.</strong></p>
        <p>Narzędzie rekrutacyjne, z którego zamierzasz skorzystać zawiera przynajmniej jedno zadanie wymagające nagranie przez Ciebie odpowiedzi w formie video. Niestety nie wszystkie urządzenia i/lub przeglądarki umożliwiają realizację tego w prawidłowy sposób.</p>
        <p><strong>Jeśli widzisz ten ekran, oznacza to, że Twoje urządzenie i/lub przeglądarka nie gwarantuje poprawnego nagrania i przesłania pliku video.</strong> Poniżej znajdziesz rekomendowane ustawienia.</p>
        <br>
        <p><strong>Urządzenia stacjonarne:</strong></p>
        <p>Windows: Google Chrome, Firefox, Opera, MS Edge</p>
        <p>macOS: Google Chrome, Firefox, Opera, Safari, MS Edge</p>
        <p>Ubuntu: Google Chrome, Firefox, Opera</p>
        <br>
        <p><strong>Urządzenia mobilne:</strong></p>
        <p>Andriod: Google Chrome, Firefox, Opera</p>
        <p>iOS (iPhone/iPad): Safari</p>
      </div>
      <hr>
    `,
  },
  skip_stage_without_record: {
    title: 'Czy na pewno chcesz pominąć zadanie?',
    content:
      '<p>Nagranie odpowiedzi video w tym zadaniu nie jest obowiązkowe, ale zalecane - pozwoli nam lepiej Cię poznać. Czy na pewno chcesz pominąć nagranie odpowiedzi video?</p>',
  },
  skip_stage_with_record: {
    title: 'Czy na pewno chcesz pominąć zadanie?',
    content:
      '<p>Zamierzasz pominąć zapisanie nagranej odpowiedzi video – nie będzie ona dostępna dla osoby prowadzącej proces rekrutacyjny. Przesłanie odpowiedzi video w tym zadaniu nie jest obowiązkowe, ale zalecane - pozwoli nam lepiej Cię poznać. Czy na pewno chcesz pominąć zapisanie nagranej odpowiedzi video?</p>',
  },
  instruction_camera: {
    content: `
      <span class="swal2-title">Rozwiązywanie problemów z kamerą i mikrofonem</span>
      <hr>
      <div class="swal2-wrapper">
      <p>Jeśli nagranie lub odtworzenie video jest nieprawidłowe, możesz skorzystać z kilku kroków, które powinny rozwiązać problem. Najczęściej przyczyny problemów wynikają z ustawień urządzenia, w tym inne równocześnie działające programy lub aplikacje korzystające z kamery i/lub mikrofonu.</p>
      <p>Możliwe rozwiązania problemu:</p>
      <ol>
        <li>Upewnij się, że przeglądarka posiada uprawnienia do korzystania z mikrofonu i kamery. Jeśli nie, zmień ustawienia i odśwież stronę.</li>
        <li>Upewnij się, że żadna inna aplikacja lub program nie wykorzystują obecnie kamery/mikrofonu (np. MS Teams, Photo Booth czy FaceTime) - wyłącz aplikacje, które blokują możliwość wykorzystania kamery przez przeglądarkę i odśwież stronę.</li>
        <li>Sprawdź czy Twoja przeglądarka jest zaktualizowana do najnowszej wersji - jeśli nie, przeprowadź aktualizację i uruchom test ponownie.</li>
        <li>Jeśli powyższe nie pomogły spróbuj skorzystać z innej przeglądarki lub urządzenia.</li>
      </ol>
      <p>Jeśli problem nadal występuje, daj nam znać na { supportEmail } przesyłając informację o urządzeniu i przeglądarce, na których pojawia się problem.</p>
      </div>
      <hr>
    `,
  },
  recaptcha: 'Zaznacz Recaptcha',
  phone_incorrect: 'Wpisz poprawny numer telefonu',
  missing_answer_error:
    'Aby przejść dalej, zaznacz dwie odpowiedzi: jedną kciukiem w górę i jedną kciukiem w dół.',
  rotate_phone_video: 'Obróć telefon poziomo, aby wyświetlić film w większym rozmiarze',
  rotate_phone_image: 'Obróć telefon poziomo, aby wyświetlić obraz w większym rozmiarze',
  attachments: 'Załączniki',
};
