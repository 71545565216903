export default {
  name: '313C',
  recruitment_game: 'Hra na zaměstnance',
  back: 'Zpět',
  cancel: 'Zrušit',
  continue: 'Pokračovat',
  skip: 'Přeskočit',
  start: 'Začít',
  close: 'Zavřít',
  help: 'Pomoc',
  time_left: 'Zbývající čas',
  progress: 'Postup',
  privacy_policy: 'Zásady ochrany osobních údajů',
  cookies_policy: 'Zásady cookies a Podmínky',
  faq: 'Otázky a odpovědi',
  messages: 'Zprávy',
  unread: 'nepřečtené',
  start_recording: 'Spuštění nahrávání',
  stop_recording: 'Zastavit nahrávání',
  remove_record: 'Odstranění záznamu',
  play_video: 'Přehrát',
  stop_video: 'Zastavit',
  mute_video: 'Ztlumit',
  unmute_video: 'Zesílit',
  replay_video: 'Přehrát znovu',
  choose: 'Vybrat',
  badges: {
    title: 'Odznaky',
    badge: 'Odznak',
    my_badges: 'Moje odznaky',
    received: 'Získáte odznak',
    continue: 'Hrajte dál',
    show_badges: 'Podívejte se na odznaky',
    show_badge: 'Uvidět odmeňu',
  },
  record_test: {
    intro: 'TEST NASTAVENÍ KAMERY A MIKROFONU',
    content:
      'Před spuštěním nástroje si otestujte svá nastavení. V poli níže byste měli vidět obraz ze své kamery. Pokud jej vidíte, znamená to, že vaše nastavení jsou pravděpodobně správná - pro jistotu nahrajte a přehrajte video. Pokud obraz z kamery nevidíte, nemůžete přehrát nahrávku nebo máte jiné pochybnosti, klikněte na tlačítko „ŘEŠENÍ PROBLÉMŮ“.',
    troubleshoot: 'Řešení Problémů',
    settings: 'Nastavení',
  },
  questions: {
    progress: 'Otázka',
    accept: 'Potvrdit',
    question_has_already_been_answered: 'Tato otázka již byla zodpovězena.',
  },
  not_found: {
    code: '404',
    title: 'Stránka neexistuje',
    content:
      'Stránka, kterou hledáte, nebyla nalezena. Mohla být odstraněna, změnila se její název nebo vůbec neexistovala',
  },
  phone: 'Telefonní číslo',
  select_language: 'Zvolte jazyk / Choose language',
  title:
    'Tato stránka používá soubory cookies, které vám poskytnou skvělé uživatelské zkušenosti. Používáním této stránky souhlasíte s používáním',
  link: 'souborů cookies',
  button: 'Zavřít',
  warning: {
    title: 'Došlo k chybě',
    content: 'Zkus to znovu a pokud se chyba bude opakovat dejte nám vědět na',
    content_without_email:
      'Zkus to znovu a pokud se chyba bude opakovat, dejte nám vědět na naší technickou podporu',
    confirm: 'Zavřít',
  },
  end_time: {
    title: 'Nahlásit chybu',
    content: 'Pokud během hry dochází k chybě, kontaktujte nás prosím e-mailem na adresu:',
  },
  record_video_error: {
    not_allowed_title: 'Chybějící oprávnění',
    not_allowed_desc:
      'Žádný přístup ke kameře a/nebo mikrofonu. Zkontrolujte nastavení prohlížeče a povolte příslušná oprávnění.',
    not_found_title: 'Žádné připojené zařízení',
    not_found_desc:
      'Nebyla zjištěna kamera ani mikrofon. Zkontrolujte, zda jsou zařízení správně připojena.',
    not_readable_title: 'Zařízení je používáno jinou aplikací',
    not_readable_desc:
      'Kamera a/nebo mikrofon se právě používají jinou aplikací. Ukončete ji a zkuste to znovu (možná bude nutné stránku obnovit).',
    unknown_title: 'Neznámá chyba',
    unknown_desc:
      'Došlo k neznámé chybě (např. problémy s ovladači nebo poškozené zařízení). Prohlédněte si sekci Řešení potíží a zkuste to znovu.',
  },
  unsupported_os: {
    content: `
          <span class="swal2-title">Nepodporované zařízení</span>
          <hr>
          <div class="swal2-wrapper">
            <p>Pro pokračování použijte <strong>jiné zařízení a/nebo jiný prohlížeč.</strong></p>
            <p>Nástroj pro nábor, který chcete použít, obsahuje alespoň jednu úlohu, která vyžaduje zaznamenání vaší odpovědi ve formátu videa. Bohužel ne všechna zařízení a/nebo prohlížeče to umožňujísprávněprovést.</p>
            <p><strong>Pokud vidíte tuto obrazovku, znamená to, že vaše zařízení a/nebo prohlížeč nedokáže zajistit správné nahrání a odeslání video souboru.</strong> Níže najdete doporučená nastavení.</p>
            <br>
            <p><strong>Stolní zařízení:</strong></p>
            <p>Windows: Google Chrome, Firefox, Opera, MS Edge</p>
            <p>macOS: Google Chrome, Firefox, Opera, Safari, MS Edge</p>
            <p>Ubuntu: Google Chrome, Firefox, Opera</p>
            <br>
            <p><strong>Mobilní zařízení:</strong></p>
            <p>Andriod: Google Chrome, Firefox, Opera</p>
            <p>iOS (iPhone/iPad): Safari</p>
          </div>
          <hr>
        `,
  },
  skip_stage_without_record: {
    title: 'Jste si jisti, že chcete úkol přeskočit?',
    content:
      '<p>Nahrávání videodopisu k tomuto úkolu není povinné, ale doporučuje se – pomůže nám vás lépe poznat. Opravdu chcete přeskočit nahrávání videodopisu?</p>',
  },
  skip_stage_with_record: {
    title: 'Jste si jisti, že chcete úkol přeskočit?',
    content:
      '<p>Chystáte se přeskočit uložení nahrané video odpovědi - nebude dostupná osobě, která provádí výběrový proces. Nahrání video odpovědi pro tuto úlohu není povinné, ale doporučuje se - pomůže nám vás lépe poznat. Jste si jisti, že chcete přeskočit uložení nahrané video odpovědi?</p>',
  },
  instruction_camera: {
    content: `
          <span class="swal2-title">Řešení problémů s kamerou a mikrofonem</span>
          <hr>
          <div class="swal2-wrapper">
          <p>Pokud je nahrávání nebo přehrávání nesprávné, můžete postupovat podle několika kroků, které by měly problém vyřešit. Nejčastější příčiny problémů vyplývají z nastavení zařízení, včetně jiných programů nebo aplikací, které současně využívají kameru a/nebo mikrofon.</p>
          <p>Možná řešení problému:</p>
          <ol>
            <li>Ujistěte se, že prohlížeč má oprávnění k používání mikrofonu a kamery. Pokud ne, změňte nastavení a obnovte stránku.</li>
            <li>Ujistěte se, že žádná jiná aplikace nebo program v současné době nevyužívá kameru/mikrofon (např. MS Teams, Photo Booth nebo FaceTime) - zavřete aplikace, které blokují možnost využití kamery prohlížečem a obnovte stránku.</li>
            <li>Zkontrolujte, zda je váš prohlížeč aktualizován na nejnovější verzi - pokud ne, proveďte aktualizaci a test znovu spusťte.</li>
            <li>Pokud výše uvedené kroky nepomohly, zkuste použít jiný prohlížeč nebo zařízení.</li>
          </ol>
          <p>Pokud problém přetrvává, dejte nám vědět na { supportEmail } zasláním informací o zařízení a prohlížeči, na kterých se problém vyskytuje.</p>
          </div>
          <hr>
        `,
  },
  recaptcha: 'Označte ReCaptcha',
  phone_incorrect: 'Zadejte platné telefonní číslo',
  missing_answer_error:
    'Pro pokračování vyberte dvě odpovědi: jednu palcem nahoru a jednu palcem dolů.',
  rotate_phone_video: 'Otočte telefon vodorovně, abyste zobrazili video ve větší velikosti',
  rotate_phone_image: 'Otočte telefon vodorovně, abyste zobrazili obrázek ve větší velikosti',
  attachments: 'Přílohy',
};
