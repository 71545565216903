export default {
  root: () => ({
    class: [
      'min-w-[12rem]',
      'rounded-md',
      'py-2',
      'bg-gray-50',
      'text-neutral-700',
      'border border-neutral-200',
    ],
  }),
  list: () => ({
    class: ['list-none', 'm-0', 'p-0', 'outline-none'],
  }),
  separator: () => ({
    class: 'border-t border-neutral-200',
  }),
  itemContent: () => ({
    class: [
      'rounded-none',
      'text-neutral-700',
      'transition-shadow',
      'duration-200',
      'hover:text-neutral-700',
      'hover:bg-neutral-100',
    ],
  }),
  item: () => ({
    class: ['m-0', 'text-sm'],
  }),
  itemLink: () => ({
    class: [
      'relative',
      'flex',
      'items-center',
      'py-3',
      'px-5',
      'text-neutral-700',
      'no-underline',
      'overflow-hidden',
      'cursor-pointer',
      'select-none',
    ],
  }),
  itemIcon: () => ({
    class: ['mr-2', 'text-neutral-600'],
  }),
  itemLabel: () => ({
    class: ['leading-none'],
  }),
  submenuLabel: () => ({
    class: [
      'font-bold',
      'm-0',
      'rounded-tl-none',
      'rounded-tr-none',
      'bg-gray-50',
      'text-sm',
      'text-neutral-700',
    ],
  }),
  transition: () => ({
    enterFromClass: 'opacity-0 scale-y-[0.8]',
    enterActiveClass:
      'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
    leaveActiveClass: 'transition-opacity duration-100 ease-linear',
    leaveToClass: 'opacity-0',
  }),
};
